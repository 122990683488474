// NOTE [mfa] The templateHeaderUrl is used in ng-include. This will not be
// processed by webpack. In order to process all the files, they are required
// here explicitly and added to the templateCache to avoid dynamic loading from
// the server.
app.run(function($templateCache) {
  $templateCache.put('views/headers/back.html',       require('views/headers/back.html'));
  $templateCache.put('views/headers/default.html',    require('views/headers/default.html'));
  $templateCache.put('views/headers/projects.html',   require('views/headers/projects.html'));
  $templateCache.put('views/headers/home.html',       require('views/headers/home.html'));
  $templateCache.put('views/headers/ar-refresh.html', require('views/headers/ar-refresh.html'));
});

app.config(["$routeProvider", "$locationProvider", function ($routeProvider, $locationProvider) {
  $locationProvider.hashPrefix('');

  return $routeProvider.when('/', {
    template: require('views/base_route.html'),
    templateHeaderUrl: null,
    controller: 'BaseRouteCtrl',
    required: {
      signIn: 0
    }
  })
    .when('/info', {
      template: require('views/info.html'),
      templateHeaderUrl: 'views/headers/back.html',
      controller: 'InfoCtrl',
      title: 'imprint'
    })
    .when('/projects', {
      template: require('views/list_project.html'),
      templateHeaderUrl: 'views/headers/projects.html',
      controller: 'listProjectsCtrl',
      title: 'overview',
      required: {
        signIn: 1
      }
    }).when('/sign-in', {
      template: require('views/sign-in.html'),
      templateHeaderUrl: null,
      controller: 'SignInCtrl',
      required: {
        signIn: 0
      }
    }).when('/privacy-summary', {
      template: require('views/privacy_summary.html'),
      templateHeaderUrl: null,
      controller: 'PrivacySummaryCtrl',
      required: {
        signIn: 1
      }
    }).when('/restore-password', {
      template: require('views/restore-password.html'),
      controller: 'RestorePasswordCtrl',
      required: {
        signIn: 0
      }
    }).when('/restore-password-by-token', {
      template: require('views/restore-password-by-token.html'),
      controller: 'RestorePasswordCtrl',
      required: {
        signIn: 0
      }
    }).when('/licenses', {
      template: require('views/licenses.html'),
      templateHeaderUrl: 'views/headers/back.html',
      controller: 'LicensesCtrl',
      title: 'softwareLicenses'
    }).when('/legal', {
      template: require('views/legal.html'),
      templateHeaderUrl: 'views/headers/home.html',
      controller: 'SideBarCtrl',
      title: 'legalInformation'
    }).when('/debug', {
      template: require('views/debug-screen.html'),
      templateHeaderUrl: 'views/headers/back.html',
      controller: 'DebugScreenCtrl',
      title: 'debug'
    }).when('/time-tracking/:id', {
      template: '<time-tracking></time-tracking>',
      templateHeaderUrl: 'views/headers/home.html',
      title: 'timeTrackings.trackingModule',
      required: {
        signIn: 1
      }
    }).when('/daily-report/:id', {
      template: '<daily-report></daily-report>',
      templateHeaderUrl: 'views/headers/default.html',
      title: 'timeTrackings.dailyReport',
      required: {
        signIn: 1
      }
    }).when('/activity-report/new', {
      template: '<activity-report-new></activity-report-new>',
      templateHeaderUrl: 'views/headers/default.html',
      title: 'timeTrackings.reportFromTN',
      required: {
        signIn: 1
      }
    }).when('/activity-report/preselected', {
      template: '<activity-report-preselected></activity-report-preselected>',
      templateHeaderUrl: 'views/headers/default.html',
      title: 'timeTrackings.reportFromTN',
      required: {
        signIn: 1
      }
    }).when('/activity-report/:id', {
      template: '<activity-report></activity-report>',
      templateHeaderUrl: 'views/headers/default.html',
      title: 'timeTrackings.reportFromTN',
      required: {
        signIn: 1
      }
    }).when('/activity-report/:id/confirm', {
      template: '<confirm-activity-report></confirm-activity-report>',
      templateHeaderUrl: 'views/headers/default.html',
      title: 'timeTrackings.reportFromTN',
      required: {
        signIn: 1
      }
    }).when('/daily-report-list', {
      template: '<daily-report-list></daily-report-list>',
      templateHeaderUrl: 'views/headers/home.html',
      title: 'timeTrackings.submissionList',
      required: {
        signIn: 1
      }
    }).when('/activity-reports/submited', {
      template: '<activity-reports-submited></activity-reports-submited>',
      templateHeaderUrl: 'views/headers/ar-refresh.html',
      title: 'activityReport.activity-reports',
      required: {
        signIn: 1
      }
    }).when('/activity-reports/submited/:id', {
      template: '<submited-activity-report></submited-activity-report>',
      templateHeaderUrl: 'views/headers/default.html',
      title: 'timeTrackings.reportFromTN',
      required: {
        signIn: 1
      }
    }).when('/activity-reports/details/:id', {
      template: '<activity-reports-details></activity-reports-details>',
      templateHeaderUrl: 'views/headers/default.html',
      title: 'activityDetails.title',
      required: {
        signIn: 1
      }
    }).when('/activity-reports/phototn-activity-report', {
      template: '<ar-phototn></ar-phototn>',
      templateHeaderUrl: 'views/headers/default.html',
      title: 'activityReport.photo-activity-report',
      required: {
        signIn: 1
      }
    })
    .when('/activation-account', {
      template: '<activation-account-send-email></activation-account-send-email>'
    })
    .when('/enter-code/:email', {
      template: '<activation-account-enter-code></activation-account-enter-code>'
    })
    .when('/enter-password/:email', {
      template: '<activation-account-enter-password></activation-account-enter-password>'
    })
    .when('/enter-password/:email/:code', {
      template: '<activation-account-enter-password></activation-account-enter-password>'
    })
    .when('/aa-successfully', {
      template: '<activation-account-successfully></activation-account-successfully>'
    })
    .when('/tutorials-list', {
      template: '<tutorials-list></tutorials-list>',
      templateHeaderUrl: 'views/headers/back.html',
      title: 'tutorial.tutorialsList',
      required: {
        signIn: 1
      }
    })
    .when('/tutorial/:tutorial', {
      template: '<tutorial></tutorial>',
      templateHeaderUrl: 'views/headers/default.html',
      title: 'tutorial.title',
      required: {
        signIn: 1
      }
    })
    .when('/pd-new', {
      template: '<photo-document-new></photo-document-new>',
      templateHeaderUrl: 'views/headers/default.html',
      title: 'photo_document.uploadPhotoDocument',
      required: {
        signIn: 1
      }
    })
    .when('/pd-list', {
      template: '<photo-document-list></photo-document-list>',
      templateHeaderUrl: 'views/headers/home.html',
      title: 'photo_document.overviewUploads',
      required: {
        signIn: 1
      }
    })
    .when('/kilometergeld', {
      template: '<kilometergeld></kilometergeld>',
      templateHeaderUrl: 'views/headers/default.html',
      title: 'mileageMoney.mileageMoney',
      required: {
        signIn: 1
      }
    })
    .when('/refer-a-friend', {
      template: '<refer-a-friend></refer-a-friend>',
      templateHeaderUrl: 'views/headers/default.html',
      title: 'refer-a-friend.title',
      required: {
        signIn: 1
      }
    })
    .when('/ebs/:assignment_id', {
      template: '<ebs-item></ebs-item>',
      templateHeaderUrl: 'views/headers/home.html',
      required: {
        signIn: 1
      }
    })
    .when('/ebs/:assignment_id/:version', {
      template: '<ebs-item></ebs-item>',
      templateHeaderUrl: 'views/headers/back.html',
      required: {
        signIn: 1
      }
    })
    .when('/support', {
      template: '<support></support>',
      templateHeaderUrl: 'views/headers/back.html',
      title: 'support.helpSupport',
      required: {
        signIn: 1
      }
    })
    .when('/blocker', {
      template: '<blocker></blocker>',
      templateHeaderUrl: null,
      required: {
        signIn: 1
      }
    })
    .when('/employee-benefits', {
      template: '<employee-benefits></employee-benefits>',
      templateHeaderUrl: 'views/headers/default.html',
      title: 'employee-benefits.title',
      required: {
        signIn: 1
      }
    })
    .when('/salary-reports-2fa', {
      template: '<salary-reports-two-factor-authentication></salary-reports-two-factor-authentication>',
      templateHeaderUrl: 'views/headers/home.html',
      title: 'salary-reports.title',
      required: {
        signIn: 1,
        feature: 'salary_reports'
      }
    })
    .when('/salary-reports-list', {
      template: '<salary-reports-list></salary-reports-list>',
      templateHeaderUrl: 'views/headers/home.html',
      title: 'salary-reports.title',
      required: {
        signIn: 1,
        feature: 'salary_reports'
      }
    })
    .when('/salary-reports/details/:id', {
      template: '<salary-report-details></salary-report-details>',
      templateHeaderUrl: 'views/headers/back.html',
      title: 'salary-reports.title',
      required: {
        signIn: 1,
        feature: 'salary_reports'
      }
    })
    .when('/releases', {
      template: '<releases></releases>',
      templateHeaderUrl: 'views/headers/home.html',
      title: 'releases',
      required: {
        signIn: 1
      }
    })
    .when('/feedback', {
      template: '<feedback></feedback>',
      templateHeaderUrl: 'views/headers/home.html',
      title: 'feedback.title',
      required: {
        signIn: 1
      }
    })
    .when('/pn-list', {
      template: '<pn-list></pn-list>',
      templateHeaderUrl: 'views/headers/home.html',
      title: 'notifications',
      required: {
        signIn: 1
      }
    })
    .when('/vr-new', {
      template: '<vr-new></vr-new>',
      templateHeaderUrl: 'views/headers/home.html',
      title: 'vacationRequest.vacationRequest',
      required: {
        signIn: 1,
        feature: 'vacation_requests'
      }
    })
    .when('/vr-new/:id', {
      template: '<vr-new></vr-new>',
      templateHeaderUrl: 'views/headers/home.html',
      title: 'vacationRequest.vacationRequest',
      required: {
        signIn: 1,
        feature: 'vacation_requests'
      }
    })
    .when('/vr-list', {
      template: '<vr-list></vr-list>',
      templateHeaderUrl: 'views/headers/home.html',
      title: 'vacationRequest.overviewVacation',
      required: {
        signIn: 1,
        feature: 'vacation_requests'
      }
    })
    .when('/vacations-overview/:id', {
      template: '<vr-list></vr-list>',
      templateHeaderUrl: 'views/headers/back.html',
      title: 'vacationRequest.overviewVacation',
      required: {
        signIn: 1,
        feature: 'vacation_requests'
      }
    })
    .when('/mm-new', {
      template: '<mileage-money-new></mileage-money-new>',
      templateHeaderUrl: 'views/headers/home.html',
      title: 'mileageMoney.createMileageMoney',
      required: {
        signIn: 1,
        feature: 'standalone_mileage_reports'
      }
    })
    .when('/mm-confirm', {
      template: '<mileage-money-confirm></mileage-money-confirm>',
      templateHeaderUrl: 'views/headers/back.html',
      title: 'mileageMoney.createMileageMoney',
      required: {
        signIn: 1,
        feature: 'standalone_mileage_reports'
      }
    })
    .when('/mm-submitted/:id', {
      template: '<mileage-money-submitted></mileage-money-submitted>',
      templateHeaderUrl: 'views/headers/back.html',
      title: 'mileageMoney.mileageMoney',
      required: {
        signIn: 1,
        feature: 'standalone_mileage_reports'
      }
    })
    .when('/mm-list', {
      template: '<mileage-money-list></mileage-money-list>',
      templateHeaderUrl: 'views/headers/home.html',
      title: 'mileageMoney.mileageMoney',
      required: {
        signIn: 1,
        feature: 'standalone_mileage_reports'
      }
    })
    .when('/profile', {
      template: '<profile></profile>',
      templateHeaderUrl: 'views/headers/home.html',
      title: 'profile.title',
      required: {
        signIn: 1,
      }
    })
    .otherwise({
      redirectTo: '/'
    });
}]);
