module.exports.configureApp = function(app) {
  // TODO [mfa] externalize
  app
    .constant("defaultApi", "staging")
    .constant("API_PATH", "/api/v2/")

    .constant("appId", { "android": "de.tempton.app", "ios": "de.tempton.unlisted.business" })
    .constant("appName", "Tempton")
    .constant("appVersion", "2.334.7")
    .constant("appBuildDate", "22/11/2024")

    .constant("INDEX_URL", "/projects")
    .constant("INDEX_PATH", {
      "android": "index.html",
      "ios": "http://localhost:6985"
    })
    // .constant("DEFAULT_APPCODE", [])
    .constant("LIST_OF_REDUCE", [
      "statistics",
      "registration",
      "download_project",
      "guest_session",
      "project_item_control",
      "data_safe"
    ])
    .constant("SKIP_PLUGINS", [
      { "name": "cordova-labs-local-webserver", "platform":"android" },
      { "name": "cordova-plugin-wkwebview-engine-v2", "platform":"android" },
      { "name": "cordova-plugin-disable-ios11-statusbar", "platform":"android" }
    ])
    .constant("APP_USAGES_BEFORE_RATING", 6)
    .constant("PUSH_CONFIGS", {
      "android": "configs/push-conf/google-services.json",
      "ios": "configs/push-conf/GoogleService-Info.plist"
    })
    .constant("FASTLANE_CONF", {})
    .constant("PDF_EXPORT", {
      "footer": {
          "de": "Erstellt von Tempton GmbH - www.tempton.de",
          "en": "Prepared by Tempton GmbH - www.tempton.de"
      },
      "emptyValue": {
          "de": "-",
          "en": "-"
      },
      "rowSeparatorMargin": 5
    })
    .constant("STATUS_BAR_COLOR", "#000000")
    .constant("APP_NAME_ID", "AM")
    .constant("BUILD_FILE", {
      "android": "./build_data/build.json"
    })
    .constant("PLUGIN_VALUES", [])
    .constant("APP_KEY_NAME", "TemptonApp")
    .constant("IMAP" , null)
    .constant("APP_TUTORIAL", null)

    .constant("custom_files", [
    ])

    .constant("ELASTIC_SEARCH", null)
}